import React, { Component } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../config/config';
import RightSection from '../common/RightSection';
import LeftSidebar from '../common/LeftSidebar';
import Navbar from '../common/layout/navbar/Navbar';
import { Button, Icon, Search, List, Label } from 'semantic-ui-react';
import './network-feed.scss';
import { SvgIcon } from '../common';
import ProfileCompletionImg from '../../assets/images/profile-completion-img.svg';
import { connect } from 'react-redux';
import { suggestedProducts } from './types';
import { bindActionCreators } from 'redux';
import { productActions } from '../../actions';
import MetaTags from 'react-meta-tags';

class NewUserView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            industries: [],
            selectedProducts: [],
            selectedProductIds: [],
            results: [],
            newProducts: [],
            scrollSet: false
        };
    }

    scrollProductListIntoView = () => {
        !this.state.scrollSet &&
            setTimeout(() => {
                document.getElementsByClassName('industries-list')[0].scrollIntoView({ behavior: 'smooth' });
                this.setState({ scrollSet: true })
            }, 500);
    }

    handleChange = (value) => {
        const selectedIndustries = [...this.state.industries];
        const index = selectedIndustries.indexOf(value);
        if (index > -1) {
            selectedIndustries.splice(index, 1)
        }
        else {
            selectedIndustries.push(value);
        }

        this.setState({ industries: selectedIndustries, scrollSet: selectedIndustries.length === 0 });
    }
    handleProductSelection = (product) => {
        const selectedProductIds = [...this.state.selectedProductIds];
        const selectedProducts = [...this.state.selectedProducts];
        const index = selectedProductIds.indexOf(product.id);
        if (index > -1) {
            selectedProductIds.splice(index, 1);
            selectedProducts.splice(index, 1);
        }
        else {
            selectedProducts.push(product);
            selectedProductIds.push(product.id);
        }
        this.setState({ selectedProducts, selectedProductIds });
    }

    handleViewFeedClick = async () => {
        await this.followProducts();
        localStorage.setItem('signUpTime', new Date().toLocaleDateString());
    }

    followProducts = async () => {
        this.setState({ showLoader: true });
        let data = {};
        const followProductCalls = this.state.selectedProducts.map(product => {
            data = { productId: product.id, productName: product.name };
            axios.post(`${BASE_URL}/api/v1/product/follow`, data, { withCredentials: true });
        })

        await axios.all([...followProductCalls]);
        setTimeout(() => {
            this.props.getFollowedProducts();
            this.props.history.push('/networkfeed/global');
        }, 1000);
    }

    replaceLastIndexWith = (text, find, replaceWith) => {
        const index = text.lastIndexOf(find);
        if (index > -1) {
            text = text.slice(0, index) + replaceWith + text.slice(index + 1);
        }
        return text;
    }

    resultRenderer = result => {
        if (result && result._source && result._source.id)
            return (
                <div key={result._source.id}>
                    <Label content={result._source.name} />
                    {
                        this.props.searchType === "company" &&
                        <Label style={{ float: 'right' }} content={result._source.country} />
                    }
                </div>
            );
    };

    handleResultSelect = (e, { result }) => {
        let newProducts = [...this.state.newProducts];
        let product = {
            id: result._source.id,
            name: result._source.name
        }
        newProducts.push(product);
        this.setState({ newProducts });
        this.handleProductSelection(product);
    }

    handleSearchChange = (e, { value }) => {
        const { searchType } = this.props;
        this.setState({ value })
        if (value.length > 4) {
            this.setState({ isLoading: true })
            axios.get(`${BASE_URL}/api/v1/product/search/${value}`, { withCredentials: true })
                .then(response => {
                    this.setState({
                        isLoading: false,
                        results: response.data
                    })
                })
        }
    }

    render() {
        const industryOptions = [
            { key: 'additiveManufacturing', value: 'additiveManufacturing', text: 'Additive Manufacturing' },
            { key: 'carbonFiber', value: 'carbonFiber', text: 'Carbon Fiber' },
            { key: 'lithiumIon', value: 'lithiumIon', text: 'Lithium-ion' },
            { key: 'opticalFiber', value: 'opticalFiber', text: 'Optical Fiber' },
            { key: 'photoVoltaic', value: 'photoVoltaic', text: 'PhotoVoltaic' },
            { key: 'UV', value: 'UV', text: 'UV/EB' },
            { key: 'semiconductor', value: 'semiconductor', text: 'Semiconductor' },
            { key: 'Greenhydrogen', value: 'Green Hydrogen', text: 'Green Hydrogen' }
        ]
        let products = [];
        let selectedIndustriesText = "";
        this.state.industries.forEach(industry => {
            if (suggestedProducts[industry] != undefined) {
                products.push(...suggestedProducts[industry]);
                selectedIndustriesText += industryOptions.find(a => a.value === industry).text + ", ";
            }
        });
        selectedIndustriesText = this.replaceLastIndexWith(selectedIndustriesText, ',', '');
        selectedIndustriesText = this.replaceLastIndexWith(selectedIndustriesText, ',', " and ");
        const { value, results, isLoading, newProducts } = this.state;

        return (
            <React.Fragment>
                <MetaTags>
                    <meta name="robots" content="noindex, nofollow" />
                </MetaTags>
                <Navbar {...this.props}>
                    <div className="profile-mainwrapper">
                        <div className="container">
                            <div className="profile-mainwrapper-inner">
                                <div className="leftbar">
                                    <LeftSidebar />
                                </div>
                                <div className="middle-section profilecompletion-section">
                                    <div className='industriesproduct-card'>
                                        <img className='right-img' src={ProfileCompletionImg} alt={ProfileCompletionImg} />
                                        <div className='industriesproduct-card-inner'>
                                            <h4>Recommended - Step 1/2</h4>
                                            <h2>There is nothing in your feed</h2>
                                            <p>Follow the industries you would like to receive updates about. With this information, we will be able to create a personalised timeline for you according to the latest industry knowledge.</p>
                                            <div className='mt-4'>
                                                <h3>I’m interested to know more about “Industries”</h3>
                                            </div>
                                            <div className='tag-list industries-list'>
                                                <ul>
                                                    {
                                                        industryOptions.map(option =>
                                                            <li key={option.value}
                                                                className={this.state.industries.includes(option.value) ? 'selected' : ''}
                                                                onClick={() => this.handleChange(option.value)}>
                                                                +{option.text}
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.industries.length > 0 &&
                                        <div className='industriesproduct-card'>
                                            <img className='right-img' src={ProfileCompletionImg} alt={ProfileCompletionImg} />
                                            <div className='industriesproduct-card-inner'>
                                                <h4>Recommended - Step 2/2</h4>
                                                <h2>Can you tell us about the products you are interested in?</h2>
                                                <p>As you are interested in {selectedIndustriesText}, we suggest you follow the products in these fields that you prefer.</p>
                                                <div className='mt-4'>
                                                    <h3><SvgIcon name='star-icon' viewbox='0 0 19 18' /> Most relevant <b>“Products”</b></h3>
                                                </div>
                                                <div className='tag-list'>

                                                    <ul>
                                                        {
                                                            products.map((product, index) =>
                                                                <li
                                                                    key={index}
                                                                    className={this.state.selectedProductIds.includes(product.id) ? 'selected' : ''}
                                                                    onClick={() => this.handleProductSelection(product)}
                                                                >
                                                                    {this.state.selectedProductIds.includes(product.id) ?
                                                                        <SvgIcon name='check' viewbox='0 0 11.385 8.685' />
                                                                        :
                                                                        "+"
                                                                    }
                                                                    &nbsp;{product.name}
                                                                </li>
                                                            )
                                                        }

                                                        {
                                                            newProducts.map((product, index) =>
                                                                <li
                                                                    key={index}
                                                                    className={this.state.selectedProductIds.includes(product.id) ? 'selected' : ''}
                                                                    onClick={() => this.handleProductSelection(product)}
                                                                >
                                                                    {this.state.selectedProductIds.includes(product.id) ?
                                                                        <SvgIcon name='check' viewbox='0 0 11.385 8.685' />
                                                                        :
                                                                        "+"
                                                                    }
                                                                    &nbsp;{product.name}
                                                                </li>
                                                            )
                                                        }
                                                        {
                                                            this.scrollProductListIntoView()
                                                        }

                                                    </ul>
                                                </div>
                                                <div>
                                                    <Search
                                                        fluid
                                                        placeholder="Search Product"
                                                        minCharacters={3}
                                                        loading={isLoading}
                                                        onResultSelect={this.handleResultSelect}
                                                        onSearchChange={this.handleSearchChange}
                                                        results={results}
                                                        value={value}
                                                        resultRenderer={this.resultRenderer}
                                                    />
                                                </div>
                                                <Button className="mt-5" primary onClick={this.handleViewFeedClick} loading={this.state.showLoader} disabled={this.state.selectedProductIds.length < 2}>View Feed</Button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="rightbar">
                                    <RightSection entityType={"user"} />
                                </div>

                            </div>
                        </div>
                    </div>
                </Navbar>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators(productActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(NewUserView);