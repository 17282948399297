import { useEffect } from "react"

const RedirectFailure = (props) => {

    // In case of error - check url and handle here
    const loginStatus = window.location.pathname.split('/').splice(-1)

    useEffect(() => {
        // "password_failed" -->>  Wrong Password for existing user
        // "otp-failed" -->>  Wrong OTP after which we check for the reason using /verify API
        
        localStorage.setItem('loginFailure', loginStatus)
        props.history.push('/otp-login')
    }, [loginStatus])

    return (
        null
    )
}

export default RedirectFailure