
import React from 'react';
import Tribute from "tributejs";
import { BASE_URL } from '../../../config/config';
import { replaceSpaceInName } from '../../../common/helperMethods';

// Require Editor JS files.
// import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
// import 'froala-editor/css/froala_style.min.css';

// import FroalaEditor from 'froala-editor';


var tagged_products = [];
var tagged_company = [];
var tributeMultipleTriggers = new Tribute({
   collection: [{
      trigger: "@",
      selectTemplate: function (item) {
         if (item !== undefined) {

            if (this.range.isContentEditable(this.current.element)) {
               tagged_company.push(item.original);
               return (
                  '<a href="' + item.original.url + '" title="' + item.original.value + '" rel="noopener noreferrer">@' + item.original.value + "</a>"
                  /* target = "_blank" */
               );
            }
            return "@" + item.original.name;
         } else {
            return '';
         }

      },


      values: function (text, cb) {
         companySearch(text, users => cb(users));
      },
      lookup: 'value',
      fillAttr: 'value'
   },
   {

      trigger: "#",
      loadingItemTemplate: '<div style="padding: 16px">Loading</div>',

      selectTemplate: function (item) {
         if (item !== undefined) {
            if (this.range.isContentEditable(this.current.element)) {
               tagged_products.push(item.original);
               return (
                  '<a href="' + item.original.url + '" rel="noopener noreferrer">#' + item.original.value.replace() + "</a>"
                  /* target = "_blank" */
               );
            }

            return "#" + item.original.value;
         } else {
            return '';
         }


      },
      values: function (text, cb) {
         productSearch(text, users => cb(users));
      },
      lookup: "value",

      fillAttr: "value"
   },
   ],
   noMatchTemplate: function (tribute) {

      return '<li><span style:"visibility: hidden;"></span></li>';
   },
   allowSpaces: true,

});

function companySearch(text, cb) {
   const hostname = window.location.protocol + '//' + window.location.host;
   if (text !== '' && text.length >= 3) {
      var URL = BASE_URL + "/api/v1/company/search";
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
         if (xhr.readyState === 4) {
            if (xhr.status === 200) {
               var data = JSON.parse(xhr.responseText);
               var filtered_json = [];
               for (let item of data) {
                  filtered_json.push({
                     "key": item._source.id,
                     "value": item._source.name,
                     //  "url": hostname + "/company/" + item._source.id
                     "url": hostname + `/company/${replaceSpaceInName(item._source.name)}/${item._source.id}`
                  });
               }

               cb(filtered_json);
            } else if (xhr.status === 403) {

               cb([]);
            }
         }
      };
      xhr.open("GET", URL + "/" + text, true);
      xhr.send();
   } else {
      var data = [];
      cb(data);
   }
}

function productSearch(text, cb) {
   // console.log("text ", text, " length ", text.length);
   const hostname = window.location.protocol + '//' + window.location.host;
   if (text !== '' && text.length >= 3) {
      var URL = BASE_URL + "/api/v1/product/search";
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function () {
         if (xhr.readyState === 4) {
            if (xhr.status === 200) {
               var data = JSON.parse(xhr.responseText);
               var filtered_json = [];
               for (let item of data) {
                  filtered_json.push({
                     "key": item._source.id,
                     "value": item._source.name,
                     // "url": hostname + "/product/" + item._source.id
                     url: hostname + `/product/${replaceSpaceInName(item._source.name)}/${item._source.id}`
                  });
               }

               cb(filtered_json);
            } else if (xhr.status === 403) {
               cb([]);
            }
         }
      };
      xhr.open("GET", URL + "/" + text, true);
      xhr.send();
   } else {
      var data = [];
      cb(data);
   }
}


/* new FroalaEditor('#froala-editor', {
   events: {
      initialized: function () {
         var editor = this;

         tributeMultipleTriggers.attach(editor.el)

         editor.events.on('keydown', function (e) {
            if (e.which == FroalaEditor.KEYCODE.ENTER && tributeMultipleTriggers.isActive) {
               return false;
            }
         }, true);
      }
   }
}) */


class CustomRickEditor extends React.Component {
   constructor(props) {
      super(props);
      this.state = { vr: null, tx: null };
      this.mention = React.createRef();
      this.textAreaInput = React.createRef();
      this.htmldiv = React.createRef();
   }


   instantHandler = () => {
      this.props.gettagged_products(tagged_products);
      this.props.gettagged_company(tagged_company);
      var res = this.mention.current.innerHTML;
      this.setState({ vr: res });
      this.props.onEditorChange(res);
   }

   handler = () => {
      var res = this.mention.current.innerHTML;
      this.mention.current.innerHTML = "";
      this.setState({ vr: res });
      this.props.onEditorChange(res);

   }

   edit = () => {
      if (this.props.value != '' && this.props.value != null) {
         this.setState({ vr: this.props.value });
      }
      this.mention.current.innerHTML = this.state.vr
   }

   textAreaSubmit = (e) => {
      this.setState({ tx: this.textAreaInput.current.value }, () => { this.htmldiv.current.innerHTML = this.state.tx });

   }

   componentDidMount() {

      tributeMultipleTriggers.attach(this.mention.current);
      this.mention.current.addEventListener("tribute-replaced", function (e) {

      });
      let that = this;
      this.mention.current.addEventListener('DOMCharacterDataModified', function (e) {
         that.instantHandler();
      }, false);

      this.mention.current.addEventListener('input', function (e) {
         that.instantHandler();
      }, false);


      if (this.props.value != undefined && this.props.value != '' && this.props.value != null) {
         this.setState({ vr: this.props.value });
         this.mention.current.innerHTML = this.props.value;
      }
      this.props.onRef(this);
   }

   componentWillReceiveProps(newProps) {
      if (newProps.value != undefined && newProps.value != '' && newProps.value != null && this.state.vr == null) {
         this.setState({ vr: newProps.value });
         this.mention.current.innerHTML = newProps.value;
      }

   }

   componentWillUnmount() {
      this.props.onRef(undefined);
   }

   clearInput = () => {

      tagged_company = [];
      tagged_products = [];
      this.setState({ vr: '' });
      this.mention.current.innerHTML = "";
   }

   render() {
      const { auth } = this.props;
      const firstname = (auth != undefined && auth.firstName) ? auth.firstName : '';
      return (
         <div className="wrapper">
            <div id="froala-editor" className="mentionable" data-placeholder={"What’s on your mind " + firstname} ref={this.mention} onKeyDown={this.keyDn} stripPastedStyles={true} ></div>
         </div>
      );
   }
}

export default CustomRickEditor;
