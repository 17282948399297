import React, { Component } from 'react';
import { Accordion, Dropdown, Search, Label } from 'semantic-ui-react'
import SvgIcon from '../../svg-icon/svg-icon';
import { BASE_URL, FRESH_CHAT_TOKEN } from '../../../../config/config';
import axios from 'axios';
import './navbar.scss';
import SignInView from '../../../authentication/signin/signin';
import { Link } from 'react-router-dom';
import Newlogo from '../../../../assets/images/mappes.io-logo.png';
import { replaceSpaceInName } from '../../../../common/helperMethods';
/* import { Freshchat } from 'reactjs-freshchat';
import 'reactjs-freshchat/dist/index.css' */
import { isDesktop, isMobile } from 'react-device-detect';
import GuestModal from '../../GuestModal';

const options = [
    { key: 1, text: 'By Company', value: 'company' },
    { key: 2, text: 'By Product', value: 'product' },
];

const resultRenderer = result => {
    if (result && result._source && result._source.id)
        return (
            <div key={result._source.id}>
                {result._index === 'products' && <Label content={result._source.name} />}
                {result._index !== 'products' && result._source.country == undefined && <Label content={result._source.name} />}
                {result._index !== 'products' && result._source.country != undefined && <Label content={result._source.name + ' (' + result._source.country + ')'} />}
                {result._index === 'products' && <Label style={{ float: 'right' }} content={'Product'} />}
                {result._index !== 'products' && <Label style={{ float: 'right' }} content={'Company'} />}
            </div>
        );
};

class NavbarPreLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchCategory: 'product',
            set_open: false
        };
    }

    componentDidMount() {
        this.resetComponent()
        setTimeout(() => {
            this.setState({ set_open: true })
        }, 4000);
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
    }

    handleResultSelect = (e, { result }) => {
        if (result._index === 'products') {
            this.props.history.push(`/product/${replaceSpaceInName(result._source.name)}/${result._source.id}`);
        } else {
            this.props.history.push(`/company/${replaceSpaceInName(result._source.name)}/${result._source.id}`);
        }
    };

    handleSearchChange = (e, { value }) => {
        console.log('searchCategory2', value);
        this.setState({ value });
        if (value.length > 2) {
            const { searchCategory } = this.state;
            this.setState({ isLoading: true });

            axios.get(`${BASE_URL}/api/v1/${searchCategory}/search/${value}`, { withCredentials: true }).then(response => {
                this.setState({
                    isLoading: false,
                    results: response.data,
                });
            });
        }
    };
    handleSearchCategoryChange = (e, { value }) => {
        this.setState({ searchCategory: value }, () => {
            this.handleSearchChange(null, { value: this.state.value });
        });
    };

    resetComponent = () => this.setState({ isLoading: false, results: [], value: '' });

    render() {
        const { isLoading, value, results, hidden, hiddenUser, activeIndex } = this.state;
        const productData = [{
            key: 1,
            name: "Optical Fiber",
            link: "/product/Single-Mode-Optical-Fiber/SkW9l-VnJRsG"
        },
        {
            key: 2,
            name: "PhotoVoltaic",
            link: "/product/Solar-cells/ryh4YXLfWP6DX"
        },
        {
            key: 3,
            name: "UV/EB Curing",
            link: "/product/Uv-Curing-Systems/B1lAQ48Mbwavm"
        },
        {
            key: 4,
            name: "Lithium-Ion",
            link: "/product/Lithium-ion-battery-Cell/SyG_WFgFX"
        },
        {
            key: 5,
            name: "Additive Manufacturing",
            link: "/product/3D-Printer/rkxIVP6OrI"
        },
        {
            key: 6,
            name: "Carbon Fiber",
            link: "/product/Carbon-Fibers/S1aD-N21Ciz"
        },
        {
            key: 7,
            name: "Semiconductor",
            link: "/product/Integrated-Circuit-ic-Or-Chip/rkdtOZ8MZPavm"
        },
        {
            key: 8,
            name: "Green Hydrogen",
            link: "/product/Green-Hydrogen/S1mF5ohWK"
        }
        ]
        return (
            <React.Fragment>
                {/* <>
                    {this.state.set_open && isDesktop ? (
                        <><Freshchat token={FRESH_CHAT_TOKEN} open={true} /> </>
                    ) : ''}
                    {isMobile ? (
                        <><Freshchat token={FRESH_CHAT_TOKEN} open={false} /> </>
                    ) : ''}

                </> */}
                <div className="mainheader">
                    <div className="left-menu">
                        <div className="leftmenu-inner">
                            <div className="menu-icon">
                                <SvgIcon name="menu-icon" width="20" viewbox="0 0 20.656 18" />
                            </div>
                            <div className="menu-list">
                                <Accordion>
                                    <Link to='/'>
                                        <Accordion.Title >
                                            Home
                                        </Accordion.Title>
                                    </Link>
                                    <a href='https://blog.mappes.io'>
                                        <Accordion.Title>
                                            Blog
                                        </Accordion.Title>
                                    </a>
                                    <Accordion.Title index={0} onClick={this.handleClick} active={activeIndex === 0}>
                                        Communities
                                        <SvgIcon className="icon dropdown" name="menu-arrow" viewbox="0 0 23.619 13.503" />
                                    </Accordion.Title>
                                    <Accordion.Content active={activeIndex === 0}>
                                        {productData.map((product, i) => (
                                            <Link to={product.link}>
                                                <Accordion.Title >
                                                    {product.name}
                                                </Accordion.Title>
                                            </Link>
                                        ))}
                                        {/* 
                                        <Link to='/product/3D-Printer/rkxIVP6OrI'>
                                            <Accordion.Title >
                                                Additive Manufacturing
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Carbon-Fibers/S1aD-N21Ciz'>
                                            <Accordion.Title>
                                                Carbon Fiber
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Lithium-ion-battery-Cell/SyG_WFgFX'>
                                            <Accordion.Title>
                                                Lithium-ion
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Single-Mode-Optical-Fiber/SkW9l-VnJRsG'>
                                            <Accordion.Title>
                                                Optical Fiber
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Solar-cells/ryh4YXLfWP6DX'>
                                            <Accordion.Title>
                                                PhotoVoltaic
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Uv-Curing-Systems/B1lAQ48Mbwavm'>
                                            <Accordion.Title>
                                                UV/EB
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Integrated-Circuit-ic-Or-Chip/rkdtOZ8MZPavm'>
                                            <Accordion.Title>
                                                Semiconductor
                                            </Accordion.Title>
                                        </Link>
                                        <Link to='/product/Green-Hydrogen/S1mF5ohWK'>
                                            <Accordion.Title>
                                                Green Hydrogen
                                            </Accordion.Title>
                                        </Link> */}
                                    </Accordion.Content>
                                    <Link to='/aboutus'>
                                        <Accordion.Title >
                                            About
                                        </Accordion.Title>
                                    </Link>
                                    {/* <Link to='/pricing'>
                                        <Accordion.Title>
                                            Pricing
                                        </Accordion.Title>
                                    </Link> */}
                                </Accordion>
                            </div>
                        </div>
                    </div>
                    <div className='prelogged-centerheader'>
                        <div className="center-logo">
                            <div className="lg-device">
                                <Link to='/' Title='A Manufacturing Community'>
                                    <SvgIcon name="logo" viewbox="0 0 907 93" />
                                    {/* <img alt={Newlogo} src={Newlogo} /> */}
                                </Link>
                            </div>
                            <div className="small-device">
                                <Link to='/'>
                                    <SvgIcon name="logo" viewbox="0 0 907 93" />
                                </Link>
                            </div>
                        </div>
                        {/* <div className='open-search'>
                        <div className='open-search'>
                            <Search
                                fluid
                                input={{ icon: 'search', iconPosition: 'left' }}
                                placeholder="Search by Company & Product"
                                minCharacters={3}
                            />
                        </div> */}
                        <div className="searchHolder landingSearchBarWrapper h-100">
                            <Search
                                fluid
                                placeholder="Search here..."
                                className="searchWithScroll dark-input landingSearchBar"
                                loading={isLoading}
                                onResultSelect={this.handleResultSelect}
                                onSearchChange={this.handleSearchChange}
                                results={results}
                                value={value}
                                resultRenderer={resultRenderer}
                                minCharacters={3}
                            />
                            <Dropdown
                                options={options} selection
                                defaultValue={options[1].value}
                                onChange={this.handleSearchCategoryChange}
                            />
                        </div>
                    </div>
                    <div className="right-action">
                        {/* <Link to="/signup" className="btn btn-joinnow mr-3">Join Now</Link> */}
                        <Link to="/otp-login" className="btn btn-signin">Login in / Sign up</Link>

                        {/* <SignInView location={this.props.location} showModal={this.props.common.showModal} showLoginModal={this.props.showLoginModal} /> */}
                    </div>
                </div>
                {this.props.children}
                {this.props.common.showModal
                    &&
                    <GuestModal
                        open={this.props.common.showModal}
                        handleClose={this.props.showLoginModal}
                    />
                }
            </React.Fragment>
        );
    }
}



export default NavbarPreLogin;
