import React, { Component } from 'react';
import { eventActions, commonActions, productActions } from '../../actions/';
import { connect } from 'react-redux';
import { Grid, Container, Responsive, Form, Input, Button, Loader, Radio, Search, Select, Message } from 'semantic-ui-react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import './addevent.scss';
import Navbar from '../common/layout/navbar/Navbar';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import { countryList } from '../../common/countries';
/* import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
 */
import src from '../../assets/images/add-event-default.png'
import { bindActionCreators } from 'redux';
import TagProductAndCompany from './TagProductAndCompany';
import { apiStatus, urlRegex } from '../../common/constants';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { formatIsoMilisecondsToYYYYMMDD, formatIsoMiliseconds, formatIsoMiliseconds2 } from '../../common/helperMethods';
import { Col, Row, SvgIcon } from '../common';
const CREATE_MODE = "CREATE_MODE";
const EDIT_MODE = "EDIT_MODE";

const startPlaceholder = {
    placeholder: 'Start date / Add time',
};

const endPlaceholder = {
    placeholder: 'End date / Add time',
};

class AddEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: EditorState.createEmpty(),
            event_id: props.match.params.id,
            src,
            allowedFileLimit: 5,
            // cropResult: src,
            formData: {
                eventType: 'offline',
                onlineEventURL: '',
                primaryProducts: [],
                companies: [],
                image: '',
                upload_images: '',
                image_preview: [],
                eventLocation: {
                    location: '',
                    area: '',
                    city: '',
                    state: '',
                    country: '',
                    pincode: '',
                }
            },
            errors: {
                //organiserWebsiteLink: '',
                eventLocationURL: false,
            },
            mode: props.match.params.id ? EDIT_MODE : CREATE_MODE
        };
        /* this.cropImage = this.cropImage.bind(this); */
    }


    componentDidMount() {
        const { getEvent, getExhibitors } = this.props;
        const { event_id } = this.state;
        if (this.state.mode === EDIT_MODE) {
            getEvent(event_id);
            getExhibitors(event_id);
        }
    }

    componentDidUpdate() {
        const isDataPrefilled = this.state.formData.id ? true : false;
        const { eventDetails, common, history } = this.props;
        if (this.state.mode === EDIT_MODE && eventDetails && !isDataPrefilled) {
            eventDetails.image_preview = (eventDetails.image) ? eventDetails.image : [];
            eventDetails.startDateTime = (eventDetails.startDate) ? new Date(+eventDetails.startDate) : '';
            eventDetails.endDateTime = (eventDetails.endDate) ? new Date(+eventDetails.endDate) : '';

            this.setState({
                formData: {
                    ...this.state.formData,
                    ...eventDetails,
                },
                // cropResult: eventDetails.image,
                // editorState: EditorState.createWithContent( ContentState.createFromBlockArray(convertFromHTML(eventDetails.details)))
            })
            setTimeout(() => {

                /* if (this.state.formData.organiserWebsiteLink) {
                    this.catch_errors('organiserWebsiteLink', this.state.formData.organiserWebsiteLink);
                } */
                if (this.state.formData.eventLocationURL) {
                    this.catch_errors('eventLocationURL', this.state.formData.eventLocationURL);
                }
            }, 1200);
            this.props.getBulkProductDetails(eventDetails.primaryProducts);
        }
        if (common.saveStatus === apiStatus.COMPLETED && eventDetails) {
            history.push(`/events/${eventDetails.id}`)
        }
    }

    handleEventType = (event, { value }) => {
        this.setState({
            formData:
                { ...this.state.formData, 'eventType': value }
        })

    }

    validEndDate = (current) => {
        if (current < this.state.formData.startDateTime) {
            return false
        } else {
            return true
        }
    }

    dateChange = (date_obj, type) => {
        var form_data = this.state.formData;
        var target = '';
        if (type == 'start') {
            target = 'startDate';

            form_data['endDate'] = date_obj;
            form_data['endDateTime'] = new Date(date_obj).getTime();;
        }

        if (type == 'end') {
            target = 'endDate';
        }

        form_data[target + 'Time'] = date_obj;
        form_data[target] = new Date(date_obj).getTime();

        this.setState({
            formData: { ...this.state.formData, form_data }
        });

    }

    handleInputChange = (event) => {
        this.setState({
            formData:
                { ...this.state.formData, [event.target.name]: event.target.value }
        })
       // this.catch_errors(event.target.name, event.target.value)

    }

    catch_errors(field, value) {
        if (/* field == 'organiserWebsiteLink' || */ field == 'eventLocationURL') {
            let errors = this.state.errors;
            errors[field] = ''
            if (value != '' && value != undefined) {
                if (!urlRegex.test(value.trim())) {
                    errors[field] = true;
                }
            } else {
                errors[field] = true;
            }
            this.setState({ errors: errors })
        }
    }

    handleEventLocation = (event) => {
        var eventLocation = this.state.formData.eventLocation
        eventLocation[event.target.name] = event.target.value
        this.setState({
            formData:
                { ...this.state.formData, eventLocation: eventLocation }
        })
    }

    handleCountrySelect = (e, { value }) => {
        var eventLocation = this.state.formData.eventLocation
        eventLocation['country'] = value
        this.setState({
            formData:
                { ...this.state.formData, eventLocation: eventLocation }
        })
    }

    /* handleEditorText = (editorState) => {
        this.setState({
            editorState,
        });

        this.setState({
            formData:
                { ...this.state.formData, ['details']: draftToHtml(convertToRaw(editorState.getCurrentContent())) }
        })
    }; */

    enablePostButton = () => {
        const { eventName, startDate, endDate, eventLocation, details, eventType } = this.state.formData;
        return eventName !== "" && eventName !== undefined &&
            startDate !== "" && startDate != undefined &&
            endDate !== "" && endDate !== undefined &&
            eventLocation.location !== "" && eventLocation.location !== undefined &&
            eventType !== "" && eventType !== undefined &&
            this.stripHtml(details) !== "" && this.stripHtml(details) != undefined &&
            this.props.common.saveStatus !== apiStatus.IN_PROGRESS

    }

    stripHtml = (html_string) => {
        if (html_string != undefined) {
            html_string = html_string.replace(/<[^>]*>?/gm, '');
            html_string = html_string.replace(/^\s+|\s+$/gm, '')
            html_string = html_string.replace(/&nbsp;/g, '')
        } else {
            html_string = '';
        }
        return html_string;
    }


    onImageChange = (e) => {
        e.preventDefault();
        let limit;
        let allowed = this.state.allowedFileLimit

        if (e.target) {
            let files = e.target.files;
            let img_obj = (this.state.mode === EDIT_MODE) ? [...this.state.formData.upload_images] : [...this.state.formData.image];
            let len = (img_obj.length !== undefined) ? img_obj.length : 1;
            let remaining = allowed - len;
            if (len <= allowed) {
                limit = (files.length >= remaining) ? remaining : files.length;
                for (let i = 0; i < limit; i++) {
                    img_obj.push(files[i])
                }
            }
            if (this.state.mode === EDIT_MODE) {
                this.setState({
                    formData: { ...this.state.formData, upload_images: img_obj }
                });
            } else {
                this.setState({
                    formData: { ...this.state.formData, image: img_obj }
                });
            }
            if (len <= allowed) {
                for (let j = 0; j < limit; j++) {
                    const reader = new FileReader();
                    reader.onload = () => {
                        let img_pre = JSON.parse(JSON.stringify(this.state.formData.image_preview));
                        img_pre.push(reader.result)
                        this.setState({
                            formData: { ...this.state.formData, image_preview: img_pre }
                        });
                    };

                    reader.readAsDataURL(files[j]);
                }
            }
        }

    }

    createEvent = () => {
        // uncomment if event location make mandatory 
       /*  if (this.state.formData.eventType == 'offline') {
            this.catch_errors('eventLocationURL', this.state.formData.eventLocationURL);
        } */

        if (/* !this.state.errors.organiserWebsiteLink && */ !this.state.errors.eventLocationURL) {
            var dataset = this.state.formData
            dataset.eventLocation = JSON.stringify(dataset.eventLocation)
            this.props.createEvent(dataset);
        }
    }

    updateEvent = () => {
        // uncomment if event location make mandatory 
        /* if (this.state.formData.eventType == 'offline') {
            this.catch_errors('eventLocationURL', this.state.formData.eventLocationURL);
        } */

        if (/* !this.state.errors.organiserWebsiteLink && */ !this.state.errors.eventLocationURL) {
            var dataset = this.state.formData
            dataset.eventLocation = JSON.stringify(dataset.eventLocation)
            this.props.updateEvent(dataset);
        }
    }

    setTaggedProducts = (tagged_products) => {
        const primaryProducts = tagged_products.filter((value, index) => tagged_products.indexOf(value) === index);
        this.setState({
            formData: { ...this.state.formData, primaryProducts }
        })
    }

    setTaggedCompanies = (tagged_companies) => {
        const items = tagged_companies.filter((value, index) => tagged_companies.indexOf(value) === index);
        this.setState({
            formData: { ...this.state.formData, companies: items, exhibitors: items }
        })
    }

    disablePreviousDates = () => {
        const { formData: { startDate } } = this.state;
        if (startDate) {
            const date = new Date(startDate);
            return date.toISOString().slice(0, 10);
        }
        return null
    }

    make_default = (i) => {
        const { image, image_preview } = this.state.formData;
        let curr = image_preview[i];
        let old = image_preview[0];
        image_preview[0] = curr;
        image_preview[i] = old

        this.setState({
            formData: { ...this.state.formData, image_preview: image_preview }
        });

    }

    removeFile = (i) => {
        const { image, image_preview } = this.state.formData;
        var fileListArr = Array.from(image)
        var fileListArr2 = Array.from(image_preview)
        fileListArr.splice(i, 1);
        fileListArr2.splice(i, 1);
        this.setState({
            formData: { ...this.state.formData, image: fileListArr, image_preview: fileListArr2 }
        });
    }

    render_images() {
        const { formData } = this.state;
        const { image_preview } = formData
        return image_preview.length > 0 && image_preview.map((item, index) => {
            return (
                <li>
                    <div className='remove-img' onClick={() => this.removeFile(index)}> <SvgIcon name='close-alt' viewbox='0 0 15 15.001' /> </div>
                    <img style={{ width: '100%' }} src={item} alt=" Event Image " onClick={() => this.make_default(index)} />
                </li>
            )
        })

    }

    render() {
        const { mode, formData, allowedFileLimit } = this.state;
        const { id, startDateTime, endDateTime, eventType, image, image_preview } = formData;
        if (mode === EDIT_MODE && !id) return <Loader active />;
        const header = mode === EDIT_MODE ? "Edit Event" : "Create Event";
        const taggedProducts = mode === EDIT_MODE ? this.props.product.bulkProductDetails || [] : formData.primaryProducts;
        const taggedCompanies = mode === EDIT_MODE ? this.props.exhibitorsCollection || [] : formData.companies;
        return (
            <Navbar {...this.props}>
                <div className="primary-background grey-bg">
                    <Container className="body-background palm-nudge-sides">
                        <Grid padded stackable>
                            <Responsive as={Grid.Column} className="left-content" minWidth={768}>
                                <LeftSidebar />
                            </Responsive>
                            <Grid.Column className='main-content-area'>
                                <div className="main-content">
                                    <div className="main-content_inner">
                                        {/* <div className="add-event-wrapper mb-4">
                                            <div className='mb-4'>
                                                <h1 className="heading">Tag products and exhibitors</h1>
                                                <p className='sub-title'>Tag product or company who are exhibiting in this event</p>
                                            </div>
                                            <Form>
                                                <h3 className='mb-1'>Add Products</h3>
                                                <p className='sub-title mt-0'>Text place holder Subtext 1 line of description needed here</p>
                                                <Row>
                                                    <Col sm='12'>
                                                        <Form.Field>
                                                            <Search fluid placeholder='Search products' />  
                                                        </Form.Field>
                                                    </Col>
                                                </Row>
                                                <h3 className='mb-1'>Add Exhibitors</h3>
                                                <p className='sub-title mt-0'>Text place holder Subtext 1 line of description needed here</p>
                                                <Row>
                                                    <Col sm='12'>
                                                        <Form.Field>
                                                            <Search fluid placeholder='Search company/exhibitors' />  
                                                        </Form.Field>
                                                    </Col>
                                                </Row>
                                                <div className='text-right mt-2'>
                                                    <Button className="button btn-cancel">
                                                        Cancel
                                                    </Button>
                                                    <Button type="submit" className="primary button btn-submit">
                                                        Save & Post event
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div> */}
                                        <div className="add-event-wrapper">
                                            <h1 className="heading">{header}</h1>
                                            <p className='sub-title'>Short 1 line / 10 words description about why to create an event</p>
                                            <Form>
                                                <h3>Type of event</h3>
                                                <div className='event-type'>
                                                    <Radio
                                                        label='Online'
                                                        name='eventType'
                                                        value='online'
                                                        checked={this.state.formData.eventType === 'online'}
                                                        onChange={this.handleEventType}
                                                    />
                                                    <Radio
                                                        label='Offline'
                                                        name='eventType'
                                                        value='offline'
                                                        checked={this.state.formData.eventType === 'offline'}
                                                        onChange={this.handleEventType}
                                                    />
                                                    {eventType == 'online' &&
                                                        <Form.Field>
                                                            <div className='mt-3 add-event-url'>
                                                                <Input name="onlineEventURL" id="onlineEventURL" placeholder='Add event URL' onChange={this.handleInputChange} value={this.state.formData.onlineEventURL} />
                                                            </div>
                                                        </Form.Field>
                                                    }
                                                </div>
                                                <div className='mt-4'>
                                                    <h3>Event Information</h3>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="eventName" id="eventName" placeholder="Event Name" onChange={this.handleInputChange} value={this.state.formData.eventName} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm='6'>
                                                            <Form.Field className='datetime-row'>
                                                                {/* <DatePicker
                                                                    selected={startDateTime}
                                                                    onChange={(date) => this.dateChange(date, 'start')}
                                                                    showTimeSelect
                                                                    holidays={[
                                                                        { date: "2023-08-15", holidayName: "India's Independence Day" }
                                                                    ]}
                                                                    openToDate={new Date()}
                                                                    dateFormat="dd-M-yyyy h:mm aa"
                                                                    placeholderText={'Start date & time'}
                                                                /> */}
                                                                <Datetime
                                                                    inputProps={startPlaceholder}
                                                                    dateFormat="DD-MM-YYYY"
                                                                    timeFormat="hh:mm A"
                                                                    onChange={(date) => this.dateChange(date, 'start')}
                                                                    value={startDateTime}
                                                                />
                                                            </Form.Field>
                                                        </Col>
                                                        <Col sm='6'>
                                                            <Form.Field className='datetime-row'>
                                                                {/* <DatePicker
                                                                    selected={endDateTime}
                                                                    onChange={(date) => this.dateChange(date, 'end')}
                                                                    showTimeSelect
                                                                    holidays={[
                                                                        { date: "2023-08-15", holidayName: "India's Independence Day" }
                                                                    ]}
                                                                    openToDate={new Date()}
                                                                    dateFormat="dd-M-yyyy h:mm aa"
                                                                    placeholderText={'End date & time'}
                                                                /> */}
                                                                <Datetime
                                                                    inputProps={endPlaceholder}
                                                                    dateFormat="DD-MM-YYYY"
                                                                    isValidDate={this.validEndDate}
                                                                    timeFormat="hh:mm A"
                                                                    onChange={(date) => this.dateChange(date, 'end')}
                                                                    value={endDateTime} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="organiserWebsiteLink" id="organiserWebsiteLink" placeholder="Event / Website URL" onChange={this.handleInputChange} value={this.state.formData.organiserWebsiteLink} />
                                                                {/* <span className='text-danger'> {this.state.errors.organiserWebsiteLink ? "Valid Website link required" : ""}</span> */}


                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    {this.state.mode !== EDIT_MODE ? (<>
                                                        {taggedProducts && <Row>
                                                            <Col>
                                                                <Form.Field>
                                                                    <TagProductAndCompany searchType="product" placeholder='Add Products' setTaggedProducts={this.setTaggedProducts} taggedItems={taggedProducts} />
                                                                </Form.Field>
                                                            </Col>
                                                        </Row>}
                                                        {taggedCompanies && <Row>
                                                            <Col>
                                                                <Form.Field>
                                                                    <TagProductAndCompany placeholder='Add Exhibitors' searchType="company" setTaggedCompanies={this.setTaggedCompanies} taggedItems={taggedCompanies} />
                                                                </Form.Field>
                                                            </Col>
                                                        </Row>}
                                                    </>) : ''}

                                                </div>
                                                <div className="add-event-image-wrapper mt-3">
                                                    <div className='upper'>
                                                        <h3 className='mb-1'>Add event picture</h3>
                                                        <p className='sub-title'>Upload event picture, im in 800px X 600px dimensions </p>
                                                    </div>
                                                    <div className='addevent-img-row'>
                                                        <div className='addevent-imgrow-inner'>
                                                            <div className='addevent-col-left'>

                                                                {image_preview.length > 0 &&
                                                                    <img style={{ width: '100%' }} src={image_preview[0]} alt=" Event Image " />
                                                                }

                                                                {image_preview.length == 0 &&
                                                                    <>
                                                                        <label className="ui mini primary button btn-sm fillr">
                                                                            <SvgIcon name='noimage-alt' viewbox="0 0 36 36" />
                                                                            Upload image
                                                                            <input type="file" multiple onChange={this.onImageChange} className="ui mini primary button btn-sm" accept=".jpg,.png,.jpeg" />
                                                                        </label>
                                                                    </>
                                                                }
                                                            </div>
                                                            <div className='addevent-col-right'>
                                                                <h4>Instructions to follow:</h4>
                                                                <ul>
                                                                    <li>Image should be in 800px X 600px dimensions (width x height)</li>
                                                                    <li>Image can be in 4:3 ratio (width x height)</li>
                                                                    <li>Images size should be less than 2 MB</li>
                                                                    <li>Support formats are .jpeg & .png</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <ul className='thumbs-list'>
                                                            {this.render_images()}

                                                            {image_preview.length < allowedFileLimit && image_preview.length > 0 && <li className='add-images-item'>
                                                                <SvgIcon name="plus2" viewbox="0 0 14 14" />
                                                                <label className="fillr">
                                                                    <input type="file" onChange={this.onImageChange} className="ui mini primary button btn-sm" accept=".jpg,.png,.jpeg" />
                                                                </label>
                                                            </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                    {/* {!this.state.cropResult &&
                                                        <button onClick={this.cropImage} className="ui mini primary button btn-sm cropBtn mT-10"><i className="crop icon text-white"></i>Crop</button>
                                                    } */}
                                                </div>
                                                <Row className='mt-4 pt-1'>
                                                    <Col>
                                                        <h3>About Event</h3>
                                                        <Form.TextArea rows={5} name="details" id="details" onChange={this.handleInputChange} placeholder='Try something about event here...' >{this.state.formData.details}</Form.TextArea>
                                                        {/* <Editor
                                                            editorState={this.state.editorState}
                                                            toolbarClassName="toolbarClassName"
                                                            wrapperClassName="wrapperClassName"
                                                            editorClassName="editorClassName"
                                                            onEditorStateChange={this.handleEditorText}
                                                            toolbar={{
                                                                inline: { inDropdown: true },
                                                                list: { inDropdown: true },
                                                                textAlign: { inDropdown: true },
                                                                link: { inDropdown: true },
                                                                history: { inDropdown: true },
                                                            }}
                                                        /> */}
                                                    </Col>
                                                </Row>
                                                <div className='mt-3'>
                                                    <h3>Event location</h3>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="location" id="location" placeholder="Event location" onChange={this.handleEventLocation} value={this.state.formData.eventLocation.location} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="area" id="area" placeholder="Area, Street, Locality, Sector" onChange={this.handleEventLocation} value={this.state.formData.eventLocation.area} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="city" id="city" placeholder="City" onChange={this.handleEventLocation} value={this.state.formData.eventLocation.city} />
                                                            </Form.Field>
                                                        </Col>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="state" id="state" placeholder="State" onChange={this.handleEventLocation} value={this.state.formData.eventLocation.state} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>

                                                        <Col>
                                                            <Form.Field>
                                                                {/*  <Input name="country" id="country" placeholder="country" onChange={this.handleEventLocation} value={this.state.formData.eventLocation.country} /> */}
                                                                <Select
                                                                    search
                                                                    id="country"
                                                                    name="country"
                                                                    placeholder='Country'
                                                                    options={countryList}
                                                                    value={this.state.formData.eventLocation.country}
                                                                    onChange={this.handleCountrySelect}
                                                                />

                                                            </Form.Field>
                                                        </Col>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="pincode" id="pincode" placeholder="Pincode" onChange={this.handleEventLocation} value={this.state.formData.eventLocation.pincode} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="eventLocationURL" id="eventLocationURL" placeholder="Location URL" onChange={this.handleInputChange} value={this.state.formData.eventLocationURL} />
                                                                <span className='text-danger'> {this.state.errors.eventLocationURL ? "Valid Location URL required" : ""}</span>
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                </div>

                                                <div className='mt-2'>
                                                    <h3>Organiser Contact info</h3>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="organiserName" id="organiserName" placeholder="Contact Person" onChange={this.handleInputChange} value={this.state.formData.organiserName} />
                                                            </Form.Field>
                                                        </Col>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="organiserEmail" id="organiserEmail" placeholder="Organiser Email Address" onChange={this.handleInputChange} value={this.state.formData.organiserEmail} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="contactSupportInfo" id="contactSupportInfo" placeholder="Contact number 1" onChange={this.handleInputChange} value={this.state.formData.contactSupportInfo} />
                                                            </Form.Field>
                                                        </Col>
                                                        <Col>
                                                            <Form.Field>
                                                                <Input name="contactSupportInfoTwo" id="contactSupportInfoTwo" placeholder="Contact number 2" onChange={this.handleInputChange} value={this.state.formData.contactSupportInfoTwo} />
                                                            </Form.Field>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {(/* this.state.errors.organiserWebsiteLink || */ this.state.errors.eventLocationURL) && (
                                                    <div className='mt-2'>
                                                        <Row>
                                                            <Col>
                                                                <Message className="reduce-msg-padding" compact visible error content={`You have form errors  `} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )
                                                }
                                                <div className='text-right mt-2'>
                                                    <Button
                                                        type="submit"
                                                        className="primary button btn-submit"
                                                        onClick={mode === EDIT_MODE ? this.updateEvent : this.createEvent}
                                                        disabled={!this.enablePostButton()}
                                                        loading={this.props.common.saveStatus === apiStatus.IN_PROGRESS}
                                                    >
                                                        {mode === EDIT_MODE ? "Update Event" : "Create & Post event"}
                                                    </Button>
                                                </div>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                            {/* <RightSection /> */}
                            <RightSection entityType="user" entityId={this.props.auth ? this.props.auth.id : null} />
                        </Grid>
                    </Container>
                </div>
            </Navbar>
        );
    }
}

function mapStateToProps({ auth, common, events, product }) {
    return {
        auth,
        common,
        eventDetails: events.eventDetails,
        product,
        exhibitorsCollection: events.exhibitorsCollection
    };
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({ ...eventActions, ...commonActions, ...productActions }, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddEvents);
