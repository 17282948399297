import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { SvgIcon } from '../../common';
import './index.scss'
import { Button, Message, Dimmer, Loader } from 'semantic-ui-react';

export const OTP = ({ navigateToEmailScreen, otp, setOtp, email, submitEmail, newUser, navigateToPasswordScreen, error, formSubmitLoading }) => {

    const [seconds, setSeconds] = useState(60)

    useEffect(() => {
        const time = setInterval(function () {
            setSeconds((second) => {
                if (second > 0) {
                    return second - 1
                } else {
                    clearInterval(time)
                }
            })
        }, 1000)
        return () => {
            clearInterval(time)
        }
    }, [])

    return (
        <div className='lwo-card-inner'>
            <Dimmer active={formSubmitLoading}>
                <Loader indeterminate>Please Wait.!</Loader>
            </Dimmer>
            <Button type="button" onClick={navigateToEmailScreen} className='back-btn'><SvgIcon name='chevron-left' viewbox="0 0 8 14" /> Back</Button>
            <div className='upper-head mt-4 pt-2'>
                <h1>We have sent you OTP</h1>
                <p className='text-center mb-0'>Enter the verification code sent to:</p>
                <p className='sentemail'><span>{email}</span> <button type='button' onClick={navigateToEmailScreen}>Edit</button></p>
            </div>
            <div className='form-item mt-3'>
                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    containerStyle="otp-input"
                    numInputs={6}
                    renderInput={(props) => <input {...props} />}
                    isInputNum
                />
            </div>
            <div className='resend-row'>
                <p>Didn’t get code?</p>
                {seconds > 0
                    ? <b>Resend 0:{seconds} sec</b>
                    : <p className='resend-otp'><button type='button' onClick={submitEmail}>Resend OTP</button></p>
                }
            </div>
            {error
                && <Message error>
                    {error}
                </Message>
            }
            <Button className='submit-btn' primary disabled={otp.length < 6} type="submit"
            >
                Submit OTP
            </Button>
            {!newUser
                && <div className='bottom-action'>
                    <Button onClick={navigateToPasswordScreen}>Log in using Password</Button>
                </div>
            }
        </div>
    )
}