import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserInfo, networkFeedActions, commonActions } from '../../../actions';
import { Icon, Popup, Transition, Feed, Button } from 'semantic-ui-react'
import CommentLayout from './Comment/CommentLayout';
import SharePost from '../SharePost';
import { SvgIcon } from '../../common';

class ArticleFooterNew extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showCommentsSection: false,
            likePostClicked: false,
            unlikePostClicked: false,
            sharePostPopup: false,
            showCommentLoader: false
        }
        this.showHideComments = this.showHideComments.bind(this);
    }

    showHideComments() {
        const { article, getPostComments, auth, history, showLoginModal } = this.props;
        const isCommentsSectionOpen = this.state.showCommentsSection;
        var loading = false;
        if (!isCommentsSectionOpen) {
            getPostComments(article.id);
            loading = true
        } else {
            loading = false
        }
        this.setState({ showCommentsSection: !isCommentsSectionOpen, showCommentLoader: loading })
    }

    componentDidUpdate(prevProps) {
        const { postCommentsCollection } = this.props;
        setTimeout(() => {
            this.setState({
                showCommentLoader: false
            })
        }, 1000);

    }

    handleLikeClick = (isPostLiked) => {
        const { likePost, unlikePost, article, auth, history, showLoginModal } = this.props;
        if (!auth) {
            showLoginModal(true);
        } else {

            const userId = auth.id;
            if (isPostLiked) {
                unlikePost(article.id, userId);
                this.setState({
                    unlikePostClicked: true,
                    likePostClicked: false
                })
            }
            else {
                likePost(article.id, userId);
                this.setState({
                    likePostClicked: true, unlikePostClicked: false
                })
            }
        }

    }
    togglesharePostPopup = () => {
        const { auth, history, showLoginModal } = this.props;
        if (!auth) {
            showLoginModal(true);
        } else {
            this.setState({ sharePostPopup: !this.state.sharePostPopup })
        }

    }
    hideSharePost = () => {
        this.setState({ sharePostPopup: false })
    }

    render() {
        const { addPostComment, likedPosts, postCommentsCollection, getUserInfo,
            userInfoCollection, auth, postLikeCount, addPostCommentStatus, sharePost, postShareStatus, setSharePostStatus } = this.props;
        let { like_count, comment_count, id, text, type, title } = this.props.article;
        if (postCommentsCollection[id] && postCommentsCollection[id].length > comment_count) {
            comment_count = postCommentsCollection[id].length;
        }
        if (postLikeCount && postLikeCount.postId === id && postLikeCount.count > like_count) {
            like_count = postLikeCount.count;
        }
        let isPostLiked = false;
        const comments = (postCommentsCollection[id] != undefined) ? postCommentsCollection[id] : [];
        if (this.state.likePostClicked) {
            isPostLiked = true;
        }
        else if (this.state.unlikePostClicked) {
            isPostLiked = false;
        }
        else if (likedPosts && likedPosts.some(post => post.parent_id == id)) {
            isPostLiked = true;
        }
        const showSharePost = this.state.sharePostPopup //&& postShareStatus !== "shared";
        const userName = auth && auth.displayName;

        let postTitle = text;
        if (type === 'article') {
            postTitle = title;
        }
        return (
            <React.Fragment>
                <Feed.Meta className={'w-100'}>
                    <div className="event-footer">
                        <div className="event-footer-left">
                            {/*    <a className="color-inherit mR-10">
                                <Icon name='eye outline' /> 139.8k
                            </a> */}
                            <a id="post-like" className={`mR-10 ${isPostLiked ? "active" : "color-inherit"}`} onClick={() => this.handleLikeClick(isPostLiked)}>
                                {isPostLiked ? <SvgIcon name='thumb-filled' viewbox="0 0 20 20" /> : <SvgIcon name='thumb-unfilled' viewbox='0 0 20 20' />} Like {like_count != 0 ? ' (' + like_count + ') ' : ''}
                            </a>
                            <a id="post-comment" className={`color-inherit mR-10 ${this.state.showCommentsSection ? "active" : ""}`} onClick={this.showHideComments}>
                                <SvgIcon className='comments-icon' name='comment-icon' viewbox='0 0 17 17' /> Comment {comment_count != 0 ? ' (' + comment_count + ') ' : ''}
                            </a>
                        </div>
                        <div className="event-footer-right">
                            <Popup
                                // basic
                                trigger={<a id="post-share" className="color-inherit" ><SvgIcon name='share-icon' viewbox='0 0 17 18.668' /> Share</a>}
                                hoverable
                                flowing
                                className="sharepost-popup-wrapper"
                                // open={showSharePost}
                                hideOnScroll
                                position='top right'
                                content={
                                    <SharePost
                                        type={type}
                                        postId={id}
                                        postTitle={postTitle}
                                        closePopup={this.togglesharePostPopup}
                                        sharePost={sharePost}
                                        postShareStatus={postShareStatus}
                                        hideSharePost={this.hideSharePost}
                                        setSharePostStatus={setSharePostStatus}
                                        userName={userName} />
                                }
                            />


                        </div>
                    </div>
                    <Transition visible={this.state.showCommentsSection} animation={"fade down"} duration={"500"}>
                        <div className="comments-box-wrap">
                            <CommentLayout comments={comments} postId={id} addPostComment={addPostComment} getUserInfo={getUserInfo} userInfoCollection={userInfoCollection} auth={auth} addPostCommentStatus={addPostCommentStatus} showCommentLoader={this.state.showCommentLoader} />
                        </div>
                    </Transition>
                </Feed.Meta>


            </React.Fragment >
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        ...bindActionCreators({ getUserInfo, ...networkFeedActions, ...commonActions }, dispatch)
    }
}
const mapStateToProps = (state) => {
    return { ...state.networkFeed }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArticleFooterNew);
