import React, { Component } from 'react';

import * as actions from '../../actions/';
import { connect } from 'react-redux';
import { Button, Icon, Container, Grid, Responsive, TextArea, Form, Feed, Modal, Dropdown, Image } from 'semantic-ui-react';
import Navbar from '../common/layout/navbar/Navbar';
import LeftSidebar from '../common/LeftSidebar';
import RightSection from '../common/RightSection';
import './feed-new.scss';
import CustomRickEditor from '../networkFeed/AddNewPost/NewsRichEditor';
// import showProfileImage from '../../assets/images/avatar.png';
import FeedImg from '../../assets/images/feed.jpg';
import { SvgIcon, Row, Col } from '../common';


const options1 = [
  { key: 1, text: <span><SvgIcon name="groups-icon" viewbox="0 0 30 21" /> Group members only</span>, value: 1 },
  { key: 2, text: <span><SvgIcon name='globe' viewbox="0 0 34.875 34.875" /> Everyone</span>, value: 2 },
  { key: 3, text: <span><SvgIcon name='globe-minus' viewbox="0 0 41.069 37.038" /> Customers Only</span>, value: 3 },
  { key: 4, text: <span><SvgIcon name='building' viewbox="0 0 31.5 36" /> Vendors Only</span>, value: 4 },
  { key: 5, text: <SelectCompaniesPrivacy />, value: 5 },
  { key: 6, text: <span><SvgIcon name='groups-icon' viewbox="0 0 30 21" /> Select users</span>, value: 6 },
  { key: 7, text: <span><SvgIcon name='suitcase' viewbox="0 0 26.028 23.1" /> Only me</span>, value: 7 },
]

const SearchResults = [
  { key: '1', value: '1', text: <React.Fragment> <div className="text-center"><Icon name="plus" />Add New</div> </React.Fragment> },
  { key: '2', value: '2', text: 'Product 2' },
  { key: '3', value: '3', text: 'Product 3' },
  { key: '4', value: '4', text: 'Product 4' },
  { key: '5', value: '5', text: 'Product 5' },
  { key: '6', value: '6', text: 'Product 6' }
]

function handleEditorText(value) {
  /*    this.setState({ text: value });
     console.log(`this.state.text `, this.state.text ) */

  this.setState({ text: value });
  let isYouTubeVideo = value.indexOf('https://www.youtube.com') > -1;
  if (isYouTubeVideo) {
    this.props.previewPostMedia('youtube', value);
  } else {
    //hide Previewer
    this.props.previewPostMedia('', '');
  }
  this.props.assemblePost('text', value);
}

function clearInput() {
  this.setState({ text: '' });
  this.child.clearInput();
}


function AddPostPoup() {
  const [open, setOpen] = React.useState(false)
  const [text, editorValue] = React.useState(false)


  return (
    <Modal
      className="createa-supplier add-post-modal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={<TextArea rows="2" placeholder='Share article, news, updates about your product or company' />}
    >
      <Modal.Content>
        <Modal.Description>
          <div className="mb-4"><b>Begin your post</b></div>
          <Form>
            <div className="d-flex mb-4 align-items-center">
              <div className="user-avtar-image">
                {/*  <Image
                  className="avatar-42 mr-3"
                  avatar
                  src={this.showProfileImage()}
                /> */}
              </div>
              <div>
                <div className="profile-name mb-1">Michelle Smith</div>
                {/* <Dropdown
                  className="selectprivacy-drop bg-white"
                  selection
                  options={options1}
                  fluid
                  placeholder='Choose an option'
                  defaultValue={1}
                /> */}
              </div>
            </div>
            <Form.Field>
              <CustomRickEditor value={text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} />
            </Form.Field>
            <Row className="pb-4">
              <Col>
                <Icon name='camera' /> Image
              </Col>
              <Col>
                <Icon name='video camera' /> Image
              </Col>
              <Col>
                <Icon name='file' /> Image
              </Col>
              <Col></Col>
            </Row>
            <div className="text-right mt-5">
              <Button className="ml-1" type='submit' onClick={() => setOpen(false)}>Cancel</Button>
              <Button className="ml-1" type='submit' primary onClick={() => setOpen(false)}>Post</Button>
            </div>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

function SelectCompaniesPrivacy() {
  const [open, setOpen] = React.useState(false)

  return (
    <Modal
      className="search-companymodal"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      size="mini"
      trigger={<span className="text"><SvgIcon name='globe' viewbox="0 0 34.875 34.875" /> Select companies</span>}>
      <Modal.Content>
        <Modal.Description>
          <div className="mb-2">
            <Dropdown
              className="search-dropdown"
              fluid
              search
              selection
              placeholder='Search Email or username'
              icon='search'
              options={SearchResults}
            />
          </div>
          <div className="mb-2"><p className="text-primary">Companies</p></div>
          <div>
            <p>Search to add all the companies who will have access to this post</p>
          </div>
        </Modal.Description>
        <Button onClick={() => setOpen(false)} basic fluid>
          Save
        </Button>
      </Modal.Content>
    </Modal>
  )
}

class FeedViewDev extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      popupOpen: false
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setPostText = this.setPostText.bind(this);
  }

  componentWillReceiveProps(newProps) {
    if ((newProps.postStatus !== this.props.postStatus && newProps.postStatus === 'created') || newProps.feedType !== this.props.feedType) {
      this.setState({ text: '' });
    }
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  setPostText(event) {
    let postText = event.target.value;
    this.setState({ text: postText });
    let isYouTubeVideo = postText.indexOf('https://www.youtube.com') > -1;
    if (isYouTubeVideo) {
      this.props.previewPostMedia('youtube', postText);
    } else {
      //hide Previewer
      this.props.previewPostMedia('', '');
    }
    this.props.assemblePost('text', postText);
  }
 
  showProfileImage() {
    console.log(`this.props`, this.props)
    const { auth, userProfileInfo } = this.props;
    if (userProfileInfo) {
      if (userProfileInfo.image && userProfileInfo.linkedinImage) {
        return (
          <React.Fragment>
            <Image
              className="avatar-48 mr-3"
              avatar
              src={userProfileInfo.image}
              srcChanged={false}
              onError={e => {
                if (!e.target.srcChanged) {
                  e.target.src = userProfileInfo.linkedinImage;
                }
                e.target.srcChanged = true;
              }}
            />
          </React.Fragment>
        );
      } else if (userProfileInfo.image) {
        return (
          <React.Fragment>
            <Image className="avatar-42 mr-3"  avatar src={userProfileInfo.image} />
          </React.Fragment>
        );
      } else if (userProfileInfo.linkedinImage) {
        return (
          <React.Fragment>
            <Image className="avatar-42 mr-3" avatar src={userProfileInfo.linkedinImage} />
          </React.Fragment>
        );
      }
    }
    return <span className="user-initials">T</span>;
       /* return <span className="user-initials">{auth.displayName.split(' ')[0].substring(0, 1)}</span>; */
  }

  handleEditorText = (value) => {
    
    this.setState({ text: value });
    
    let isYouTubeVideo = value.indexOf('https://www.youtube.com') > -1;
    if (isYouTubeVideo) {
      this.props.previewPostMedia('youtube', value);
    } else {
      this.props.previewPostMedia('', '');
    }
    this.props.assemblePost('text', value);
  }

  clearInput() {
    this.setState({ text: '' });
    this.child.clearInput();
  }

  handleOpen(status) {
    this.setState({ popupOpen: status });
  }

  render() {

    return (
      <Navbar {...this.props}>
        {console.log("props ", this.props)}
        <div className="primary-background">
          <Container className="body-background palm-nudge-sides">
            <Grid padded stackable>
              <Responsive as={Grid.Column} className="left-content" minWidth={768}>
                <LeftSidebar />
              </Responsive>
              <Grid.Column className="main-content-area">
                <div className="feeds-wrapper">
                  <div className="feeds-top-section">
                    <div className="user-avatar">
                      {/* <img src={this.showProfileImage()} /> */}
                      {this.showProfileImage()}
                    </div>
                    <div className="post-add">
                      {/*     <AddPostPoup /> */}
                      <Form>
                        
                      <Modal
                        className="createa-supplier add-post-modal"
                        onClose={()=> this.handleOpen(false)}
                          onOpen={() =>this.handleOpen(true)}
                        open={this.state.popupOpen}
                        size="mini"
                        trigger={<TextArea rows="2" placeholder='Share article, news, updates about your product or company' />}
                      >
                        <Modal.Content>
                          <Modal.Description>
                            <div className="mb-4"><b>Begin your post</b></div>
                            <Form>
                              <div className="d-flex mb-4 align-items-center">
                                <div className="user-avtar-image">
                                    {this.showProfileImage()}
                                  {/* <Image
                                    className="avatar-42 mr-3"
                                    avatar
                                    src={this.showProfileImage()}
                                  /> */}
                                </div>
                                <div>
                                  <div className="profile-name mb-1">Michelle Smith</div>
                                  <div className="ml-1 mt-1">​Start writing; you can use # to mention product and @ ​for companies</div>
                                  {/* <Dropdown
                  className="selectprivacy-drop bg-white"
                  selection
                  options={options1}
                  fluid
                  placeholder='Choose an option'
                  defaultValue={1}
                /> */}
                                </div>
                              </div>
                              <Form.Field>
                                <CustomRickEditor value={this.state.text} onEditorChange={this.handleEditorText} onRef={ref => (this.child = ref)} />
                              </Form.Field>
                              <Row className="pb-4">
                                <Col>
                                  <Icon name='camera' /> Image
                                </Col>
                                <Col>
                                  <Icon name='video camera' /> Image
                                </Col>
                                <Col>
                                  <Icon name='file' /> Image
                                </Col>
                                <Col></Col>
                              </Row>
                              <div className="text-right mt-5">
                                  <Button className="ml-1" type='submit' onClick={() => this.handleOpen(false)}>Cancel</Button>
                                  <Button className="ml-1" type='submit' primary onClick={() => this.handleOpen(false)}>Post</Button>
                              </div>
                            </Form>
                          </Modal.Description>
                        </Modal.Content>
                      </Modal>
                      </Form>
                    </div>
                    <Button primary>Post</Button>
                  </div>
                  <div className="feeds-container">
                    <Feed>
                      <Feed.Event>
                        <div className="event-head">
                          <div className="event-head-left">
                            <Feed.Label image={this.showProfileImage()} /> 
                         
                            <div className="header-dtl">
                              <h4>Ankit Singhal</h4>
                              <p>April 1, 2021, 2:50 am <SvgIcon name="building" viewbox="0 0 31.5 36" /></p>
                            </div>
                          </div>
                          <div className="event-head-right">
                            000
                          </div>
                        </div>
                        <Feed.Content>
                          <Feed.Extra text>
                            Ours is a life of constant reruns. We're always circling back to where
                            we'd we started, then starting all over again. Even if we don't run
                            extra laps that day, we surely will come back for more of the same
                            another day soon.
                          </Feed.Extra>
                          <Feed.Summary>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                          </Feed.Summary>
                          <Feed.Extra images>
                            <img src={FeedImg} />
                          </Feed.Extra>
                          <Feed.Meta>
                            <div className="event-footer">
                              <div className="event-footer-left">
                                <a className="color-inherit mR-10">
                                  <Icon name='eye outline' /> 139.8k
                                </a>
                                <a className="mR-10 ${isPostLiked">
                                  <Icon name="thumbs up outline" /> Like (15)
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='comment outline' /> Comment
                                </a>
                              </div>
                              <div className="event-footer-right">
                                <a className="color-inherit mR-10">
                                  <Icon name='share square' /> Share
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='tag outline' /> Tag
                                </a>
                              </div>
                            </div>
                          </Feed.Meta>
                        </Feed.Content>
                      </Feed.Event>

                      <Feed.Event>
                        <div className="event-head">
                          <div className="event-head-left">
                            <Feed.Label image={this.showProfileImage()} />
                            <div className="header-dtl">
                              <h4>Ankit Singhal</h4>
                              <p>April 1, 2021, 2:50 am <SvgIcon name="building" viewbox="0 0 31.5 36" /></p>
                            </div>
                          </div>
                          <div className="event-head-right">
                            000
                          </div>
                        </div>
                        <Feed.Content>
                          <Feed.Extra text>
                            Ours is a life of constant reruns. We're always circling back to where
                            we'd we started, then starting all over again. Even if we don't run
                            extra laps that day, we surely will come back for more of the same
                            another day soon.
                          </Feed.Extra>
                          <Feed.Summary>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                          </Feed.Summary>
                          <Feed.Meta>
                            <div className="event-footer">
                              <div className="event-footer-left">
                                <a className="color-inherit mR-10">
                                  <Icon name='eye outline' /> 139.8k
                                </a>
                                <a className="mR-10 ${isPostLiked">
                                  <Icon name="thumbs up outline" /> Like (15)
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='comment outline' /> Comment
                                </a>
                              </div>
                              <div className="event-footer-right">
                                <a className="color-inherit mR-10">
                                  <Icon name='share square' /> Share
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='tag outline' /> Tag
                                </a>
                              </div>
                            </div>
                          </Feed.Meta>
                        </Feed.Content>
                      </Feed.Event>

                      <Feed.Event>
                        <div className="event-head">
                          <div className="event-head-left">
                            <Feed.Label image={this.showProfileImage()} />
                            <div className="header-dtl">
                              <h4>Ankit Singhal</h4>
                              <p>April 1, 2021, 2:50 am <SvgIcon name="building" viewbox="0 0 31.5 36" /></p>
                            </div>
                          </div>
                          <div className="event-head-right">
                            000
                          </div>
                        </div>
                        <Feed.Content>
                          <Feed.Extra text>
                            Ours is a life of constant reruns. We're always circling back to where
                            we'd we started, then starting all over again. Even if we don't run
                            extra laps that day, we surely will come back for more of the same
                            another day soon.
                          </Feed.Extra>
                          <Feed.Summary>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                          </Feed.Summary>
                          <Feed.Meta>
                            <div className="event-footer">
                              <div className="event-footer-left">
                                <a className="color-inherit mR-10">
                                  <Icon name='eye outline' /> 139.8k
                                </a>
                                <a className="mR-10 ${isPostLiked">
                                  <Icon name="thumbs up outline" /> Like (15)
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='comment outline' /> Comment
                                </a>
                              </div>
                              <div className="event-footer-right">
                                <a className="color-inherit mR-10">
                                  <Icon name='share square' /> Share
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='tag outline' /> Tag
                                </a>
                              </div>
                            </div>
                          </Feed.Meta>
                        </Feed.Content>
                      </Feed.Event>

                      <Feed.Event>
                        <div className="event-head">
                          <div className="event-head-left">
                            <Feed.Label image={this.showProfileImage()} />
                            <div className="header-dtl">
                              <h4>Ankit Singhal</h4>
                              <p>April 1, 2021, 2:50 am <SvgIcon name="building" viewbox="0 0 31.5 36" /></p>
                            </div>
                          </div>
                          <div className="event-head-right">
                            000
                          </div>
                        </div>
                        <Feed.Content>
                          <Feed.Extra text>
                            Ours is a life of constant reruns. We're always circling back to where
                            we'd we started, then starting all over again. Even if we don't run
                            extra laps that day, we surely will come back for more of the same
                            another day soon.
                          </Feed.Extra>
                          <Feed.Summary>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                            <a>#Single mode</a> <a>#Single mode</a> <a>#Single mode</a>
                          </Feed.Summary>
                          <Feed.Meta>
                            <div className="event-footer">
                              <div className="event-footer-left">
                                <a className="color-inherit mR-10">
                                  <Icon name='eye outline' /> 139.8k
                                </a>
                                <a className="mR-10 ${isPostLiked">
                                  <Icon name="thumbs up outline" /> Like (15)
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='comment outline' /> Comment
                                </a>
                              </div>
                              <div className="event-footer-right">
                                <a className="color-inherit mR-10">
                                  <Icon name='share square' /> Share
                                </a>
                                <a className="color-inherit mR-10">
                                  <Icon name='tag outline' /> Tag
                                </a>
                              </div>
                            </div>
                          </Feed.Meta>
                        </Feed.Content>
                      </Feed.Event>

                    </Feed>
                  </div>
                </div>
              </Grid.Column>
              <RightSection entityType="company" />
            </Grid>
          </Container >
        </div>
      </Navbar>
    );
  }
}


export default FeedViewDev;