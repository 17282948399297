import React from 'react';
import ProductsList from './product-list';
import { Table, Loader } from 'semantic-ui-react';
import AddProduct from '../common/AddProduct/AddProduct';

const ProductsTab = (props) => {
    const { company, id, products, isCompanyUser, auth, addProducts } = props;

    const addProductsToList = (addedProducts) => {
        addProducts(id, company.name, addedProducts);
    }

    if (!company) {
        return 'Fetching Company Details'
    }

    return (
        <div className="supplierslist-wrapper border-1-radius">
            {auth &&
                <AddProduct companyName={ company.name }
                    onSubmit={addProductsToList}
                />
            }
            <div className="supplier-list">
                <Table basic="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                <div className="d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        {company.name} Products
                                    </div>
                                </div>
                            </Table.HeaderCell>
                            {/* <Table.HeaderCell className="text-right">
                                <Button primary basic className="py-2 px-3">Request Quote</Button>
                            </Table.HeaderCell> */}
                        </Table.Row>
                    </Table.Header>
                    {(products && !products.length)
                        ? <Table.Body>
                            <ProductsList products={products} isCompanyUser={isCompanyUser} />
                        </Table.Body>
                        : <div>
                            Fetching Products
                            <Loader></Loader>
                        </div>
                    }
                </Table>
            </div>

        </div>
    )
}

export default ProductsTab;