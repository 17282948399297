import axios from 'axios';
import { BASE_URL } from '../../config/config';
import {
    CREATE_EVENT, EVENT_DETAILS, EVENT_LIST, EXHIBITORS_COLLECTION,
    ATTENDEES, RESET_EVENT, ALL_EVENTS, EVENT_DELETED, RESET_EXHIBITORS_COLLECTION, VOTES, RESET_ATTENDEES_COLLECTION, INDUSTRY_EVENT_LIST, SET_EVENT_PAGINATION, RSVP_EVENTS, HALLBOOTHSAVEFAIL, HALLBOOTHSAVESUCCESS, EXIBITOR_FILTER_PRODUCT_LIST, EXIBITORFILTERSUCCESS, EXIBITORSEARCHSUCCESS
} from '../../config/types';
import { getUserProfileInfo } from '../index';
import { setSaveStatus } from '../common/common';
import { apiStatus } from '../../common/constants';

export const createEvent = (formData) => async (dispatch) => {
    const newFormData = new FormData();
    let value;
    Object.keys(formData).forEach(field => {
        if (field == 'image') {
            // multiple file upload
            if (formData[field] != '' && formData[field].length > 0) {
                for (let i = 0; i < formData[field].length; i++) {
                    newFormData.append(`image[${i}]`, formData[field][i])
                }
            }
        } else {
            if (field != 'image_preview') {
                if (Array.isArray(formData[field])) {
                    value = JSON.stringify(formData[field]);
                }
                else {
                    value = formData[field];
                }
                newFormData.append(field, value)
            }
        }
    })

    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        const event = await axios.post(`${BASE_URL}/api/v1/events`, newFormData, {
            headers: { "content-type": "multipart/form-data" },
            withCredentials: true
        });
        dispatch({ type: CREATE_EVENT, payload: true })
        dispatch({ type: EVENT_DETAILS, payload: event.data })
        if (event.data.votes && event.data.votes.length > 0) {
            dispatch(getAttendees(event.data.votes));
        }
        dispatch(setSaveStatus(apiStatus.COMPLETED));
    }
    catch (err) {
        dispatch(setSaveStatus(apiStatus.UNDEFINED));

    }

};

export const updateEvent = (formData) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    const { id } = formData;
    const newFormData = new FormData();
    let value;
    Object.keys(formData).forEach(field => {
        if (field == 'upload_images') {
            // multiple file upload
            if (formData[field] != '' && formData[field].length > 0) {
                for (let i = 0; i < formData[field].length; i++) {
                    newFormData.append(`upload_images[${i}]`, formData[field][i])
                }
            }
        } else {
            if (field != 'image_preview') {
                if (Array.isArray(formData[field])) {
                    value = JSON.stringify(formData[field]);
                }
                else {
                    value = formData[field];
                }
                newFormData.append(field, value)
            }
        }
    })
    await axios.put(`${BASE_URL}/api/v1/events/${id}`, newFormData, {
        headers: { "content-type": "multipart/form-data" },
        withCredentials: true
    });
    dispatch(getEvent(id));
    dispatch(setSaveStatus(apiStatus.COMPLETED));
};

export const getEvent = (eventId) => async (dispatch) => {
    const eventDetails = await axios.get(`${BASE_URL}/api/v1/events/${eventId}`, { withCredentials: true });
    const { createdBy, exhibitors } = eventDetails.data;

    dispatch({ type: EVENT_DETAILS, payload: eventDetails.data })
    dispatch(reseteventagination());
    dispatch(getUserProfileInfo(createdBy));
};

export const getAllEvent = (type) => async (dispatch, getState) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));

        const state = getState();
        const { size, start } = state.events.eventPagination;
        let url = `${BASE_URL}/api/v1/events?type=${type}&size=${size}&start=${start}&pageNo=${start}`;
        const eventDetails = await axios.get(url, { withCredentials: true });
        dispatch(updateTotalCountInPagination(eventDetails.data.total));
        dispatch({ type: ALL_EVENTS, payload: eventDetails.data.items })
        setTimeout(() => {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
            setTimeout(() => {
                dispatch(setSaveStatus(apiStatus.UNDEFINED));
            }, 200);
        }, 500);
    } catch (err) {
        console.log('err ', err)
        dispatch({ type: ALL_EVENTS, payload: err.response.data.message })
        setTimeout(() => {
            dispatch(setSaveStatus(apiStatus.COMPLETED));
            setTimeout(() => {
                dispatch(setSaveStatus(apiStatus.UNDEFINED));
            }, 200);
        }, 500);
    }

};

export const getRsvpEvent = (userId) => async (dispatch, getState) => {
    try {
        let res = await axios.get(`${BASE_URL}/api/v1/events/user/rsvp`, { withCredentials: true });
        console.log('res ', res.data)
        let event_list = res.data;
        dispatch(setSaveStatus(apiStatus.UNDEFINED));
        dispatch(updateTotalCountInPagination(event_list.total));
        dispatch({ type: RSVP_EVENTS, payload: event_list })
    } catch (err) {
        dispatch(setSaveStatus(apiStatus.UNDEFINED));
        console.log('err ', err)
    }
}

export const getEventsList = (type, id) => async (dispatch) => {
    const eventsList = await axios.get(`${BASE_URL}/api/v1/events?type=${type}&id=${id}`, { withCredentials: true });
    let sortedEventsList = [];
    if (eventsList.data.items && eventsList.data.items.length > 0) {
        sortedEventsList = eventsList.data.items.sort((a, b) => { return (new Date(+a.startDate) > new Date(+b.startDate) ? 1 : -1) })
    }
    dispatch({ type: EVENT_LIST, payload: sortedEventsList });
};

export const getEventsListForIndustry = (ids) => async (dispatch) => {
    const formattedIds = ids.map(id => `ids=${id}`).join('&');
    const eventsList = await axios.get(`${BASE_URL}/api/v1/events?type=product&${formattedIds}`, { withCredentials: true });
    let sortedEventsList = [];
    if (eventsList.data.items && eventsList.data.items.length > 0) {
        sortedEventsList = eventsList.data.items.sort((a, b) => { return (new Date(+a.startDate) > new Date(+b.startDate) ? 1 : -1) })
    }
    dispatch({ type: INDUSTRY_EVENT_LIST, payload: sortedEventsList });
};

export const updateVote = (eventId, vote) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    await axios.post(`${BASE_URL}/api/v1/events/${eventId}/votes`, { vote }, { withCredentials: true });
    dispatch(getVotes(eventId));
    dispatch(setSaveStatus(apiStatus.COMPLETED));

};

export const setEventPagination = (eventPagination) => async (dispatch) => {
    dispatch({ type: SET_EVENT_PAGINATION, payload: eventPagination });
}

const updateTotalCountInPagination = (totalCount) => async (dispatch, getState) => {
    const state = getState();
    const { eventPagination } = state.events;

    const eventPaginationNew = {
        size: eventPagination.size,
        start: eventPagination.start,
        total: totalCount
    }
    dispatch(setEventPagination(eventPaginationNew));
}

const reseteventagination = () => async (dispatch) => {
    const eventPagination = {
        start: 1,
        size: 50,
    }

    dispatch({ type: SET_EVENT_PAGINATION, payload: eventPagination });
}


export const getExhibitors = (id, searchString, user_flag = false, filter = false, products, saved = "false") => async (dispatch, getState) => {
    dispatch({ type: EXIBITORSEARCHSUCCESS, payload: true });

    if (user_flag == true || filter || searchString || saved == "true") {
        dispatch({ type: EXHIBITORS_COLLECTION, payload: [] });
        dispatch(updateTotalCountInPagination(0));
    }
    // searchString = (searchString != undefined && searchString != '' && searchString != null) ? `&search=${searchString}` : ''
    // let url = `${BASE_URL}/api/v1/events/${id}/exhibitors/detail?size=${size}&pageNo=${start}${searchString}${user_flag}`;
    const state = getState();
    const { size, start } = state.events.eventPagination;
    user_flag = (user_flag === true) ? true : false
    if (saved == "true"){
        filter = true;
    }
    let data_obj = {
        "id": id,
        "pageNo": start,
        "size": size,
        "filter": filter,
        "search": searchString,
        "user": user_flag,
        "products": products,
        "saved": saved
    }

    let url = `${BASE_URL}/api/v1/events/exhibitors/detail`;
    const res = await axios.post(url, data_obj, { withCredentials: true });
    let exhibitors = [];
    if (res.data.exhibitors.length) {
        exhibitors = res.data.exhibitors;
    } else if (res.data.length) {
        exhibitors = res.data;
    }
    if (exhibitors && exhibitors.length > 0) {
        // exhibitors.sort((a, b) => b.count - a.count);
        dispatch(updateTotalCountInPagination(res.data.totalCount));
        dispatch({ type: EXHIBITORS_COLLECTION, payload: exhibitors });
        dispatch({ type: EXIBITORSEARCHSUCCESS, payload: false });

    }
    else {
        dispatch({ type: EXHIBITORS_COLLECTION, payload: [] })
        dispatch({ type: EXIBITORSEARCHSUCCESS, payload: false });

    }
}

export const getExhibitorFilterProducts = (id, searchString) => async (dispatch) => {
    try {
        dispatch({ type: EXIBITORFILTERSUCCESS, payload: true });
        searchString = (searchString != undefined && searchString != '' && searchString != null) ? `?search=${searchString}` : ''
        const prodList = await axios.get(`${BASE_URL}/api/v1/events/${id}/products${searchString}`, { withCredentials: true });
        let sortedprodList = [];
        if (prodList.data && prodList.data.length > 0) {
            // sortedprodList = prodList.data.sort((a, b) => { return (new Date(+a.startDate) > new Date(+b.startDate) ? 1 : -1) })
            sortedprodList = prodList.data;
        }
        dispatch({ type: EXIBITORFILTERSUCCESS, payload: false });
        dispatch({ type: EXIBITOR_FILTER_PRODUCT_LIST, payload: sortedprodList });
    } catch (err) {
        dispatch({ type: EXIBITORFILTERSUCCESS, payload: false });
    }

};

export const addExhibitors = (id, exhibitors) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    await axios.post(`${BASE_URL}/api/v1/events/${id}/exhibitors`, exhibitors, { withCredentials: true })
    await dispatch(getEvent(id));
    await dispatch(getExhibitors(id));
    setTimeout(() => {
        dispatch(setSaveStatus(apiStatus.COMPLETED));
    }, 500);
}

export const addBookmark = (id, exhibitorId) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    await axios.put(`${BASE_URL}/api/v1/events/${id}/bookmark/add`, { "exhibitorId": exhibitorId }, { withCredentials: true });
    dispatch(setSaveStatus(apiStatus.COMPLETED));
};

export const removeBookmark = (id, exhibitorId) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    await axios.delete(`${BASE_URL}/api/v1/events/${id}/bookmark/remove/${exhibitorId}`, { withCredentials: true });
    dispatch(setSaveStatus(apiStatus.COMPLETED));
};

export const addHallBooth = (id, update_dataset) => async (dispatch) => {
    try {
        dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
        await axios.put(`${BASE_URL}/api/v1/events/${id}/exhibitors/update`, update_dataset, { withCredentials: true });
        dispatch(setSaveStatus(apiStatus.COMPLETED));
        dispatch({ type: HALLBOOTHSAVESUCCESS, payload: true });
        setTimeout(() => {
            dispatch({ type: HALLBOOTHSAVESUCCESS, payload: undefined });
        }, 500);
    } catch (err) {
        dispatch(setSaveStatus(apiStatus.UNDEFINED));
        dispatch({ type: HALLBOOTHSAVEFAIL, payload: true });
        setTimeout(() => {
            dispatch({ type: HALLBOOTHSAVEFAIL, payload: undefined });
        }, 500);

    }
};

const getAttendees = (votes) => async (dispatch) => {
    let userInfo;
    let promises = [];
    const userIds = Object.keys(votes);
    userIds.forEach(userId => {
        userInfo = axios.get(`${BASE_URL}/api/v1/user/profile/byId/${userId}`, {
            withCredentials: true
        })
        promises.push(userInfo);
    })
    Promise.all(promises).then(response => {
        const attendees = response.map(item => ({ ...item.data.Item, vote: votes[item.data.Item.id] }));
        dispatch({ type: ATTENDEES, payload: attendees });
    })
}

export const tagProductsAndCompanies = (eventId, data) => async (dispatch) => {
    dispatch(setSaveStatus(apiStatus.IN_PROGRESS));
    const res = await axios.post(`${BASE_URL}/api/v1/events/${eventId}/tags`, data, { withCredentials: true })
    await dispatch(getEvent(eventId, true));
    setTimeout(() => {
        dispatch(setSaveStatus(apiStatus.COMPLETED));
    }, 500);
}

export const deleteEvent = (eventId) => async (dispatch) => {
    await axios.delete(`${BASE_URL}/api/v1/events/${eventId}`, { withCredentials: true })
    dispatch(getEventsList());
    dispatch({ type: EVENT_DELETED, payload: true });
}

export const resetEvent = () => async (dispatch) => {
    dispatch({ type: RESET_EVENT });
    dispatch(reseteventagination());
}

export const getVotes = (id, related) => async (dispatch) => {
    dispatch({ type: RESET_ATTENDEES_COLLECTION });

    let url;
    if (related) {
        url = `${BASE_URL}/api/v1/events/${id}/votes?related=true`;
    }
    else {
        url = `${BASE_URL}/api/v1/events/${id}/votes`;
    }
    const res = await axios.get(url, { withCredentials: true })
    dispatch(getAttendees(res.data.Item.votes));
    dispatch({ type: VOTES, payload: res.data.Item.votes });
}

const createBodyFormData = formData => {
    const bodyFormData = new FormData();
    let value;
    Object.keys(formData).forEach(field => {
        if (Array.isArray(formData[field])) {
            value = JSON.stringify(formData[field]);
        }
        else {
            value = formData[field];
        }
        bodyFormData.append(field, value)
    })
    return bodyFormData;
}