import React, { useState }  from 'react';
import { Button } from 'semantic-ui-react';
import './index.scss';
import { Link } from 'react-router-dom';

const GuestModal = (props) => {

    return (
        <>
            {props.open && 
                <div className="guest-modal" onClick={() => props.handleClose(false)}>
                    <div className='guestmodal-inner' onClick={ (e) => e.stopPropagation() }>
                        <div className='left-icon'>
                            <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.62964 18.5107L24.0154 0.125027L40.3582 18.5107L24.0154 34.8536L5.62964 18.5107Z" fill="#77AAF1"/>
                                <path d="M5.62964 18.5107L24.0154 0.125027L19.9296 18.5107L24.0154 34.8536L5.62964 18.5107Z" fill="#4083DF"/>
                                <path d="M5.62964 18.5107L22.9939 18.5107H40.3582L24.0154 34.8536L5.62964 18.5107Z" fill="#4083DF" fill-opacity="0.5"/>
                                <path d="M19.6469 9.73977C21.9367 9.73977 25.2231 12.4847 23.0245 12.4847C17.958 12.4847 6.24803 12.2882 3.18001 12.4847C0.11199 12.6812 -0.420881 10.9344 1.4911 10.426L1.51695 10.4191C2.61176 10.1282 7.91052 8.7199 8.67134 6.77079C9.43835 4.80583 13.2734 3.43036 14.8074 4.80583C16.3414 6.1813 16.801 9.73977 19.6469 9.73977Z" fill="#FCE8B4"/>
                                <path d="M38.0778 27.6199C39.29 27.6199 41.0299 28.9923 39.8659 28.9923C37.1836 28.9923 30.9843 28.8941 29.36 28.9923C27.7358 29.0906 27.4537 28.2172 28.4659 27.963L28.4796 27.9596C29.0592 27.8141 31.8644 27.11 32.2672 26.1354C32.6732 25.1529 34.7036 24.4652 35.5157 25.1529C36.3278 25.8406 36.5711 27.6199 38.0778 27.6199Z" fill="#FCE8B4"/>
                            </svg>
                        </div>
                        <div>
                            <h2>You are now <span>“Guest user”</span></h2>
                            <p>
                                Please, Log in/Sign up to the account to get full access
                            </p>
                            <Link to='/otp-login'><Button primary basic>Log in / Sign up</Button></Link>
                        </div>
                    </div>
                </div>
            }
        </>
    );
} 

export default GuestModal;