import React from 'react';

const FileUploadControl = (props) => {
    var acceptedType;
    if (props.accept != undefined && props.accept != '') {
        acceptedType = props.accept
    } else {
        acceptedType = "*"
    }

    function clickTrigger() {
        /*  console.log('in here ')
         document.querySelector('input#myfile').click() */
    }

    return (

        <div className="input-type-file large" onClick={clickTrigger}>
            <input type='file' className='file-input' id="myfile" onChange={props.handleFileUpload}  {...props.accept && { accept: props.accept }} />
            <i className="icon-cloud-uplod"></i>
            <p>Drag and drop a file here or click</p>
        </div>
    )
}

export default FileUploadControl;